import React from 'react'
import MaterialIcon from '@material/react-material-icon'
import MenuSurface, { Corner } from '@material/react-menu-surface'
import Link from 'gatsby-link'

import langs from '../data/languages'

export default class SelectLanguage extends React.Component {
  state = {
    open: false,
    anchorElement: null,
  }

  setAnchorElement = element => {
    if (this.state.anchorElement) {
      return
    }
    this.setState({ anchorElement: element })
  }

  close = () => {
    this.setState({open: false})
  }

  render() {
    const links = langs.languages.map(lang => {
      const url = lang === langs.defaultLangKey ? '/' : `/${lang}/`
      return (
        <Link key={lang} onClick={this.close} className='mdc-list-item' to={url}>{langs.languagesNames[lang]}</Link>
      )
    })
    return (
      <div className="mdc-menu-surface--anchor" ref={this.setAnchorElement}>
        <MaterialIcon
          className="im-header__action-item"
          onClick={() => this.setState({ open: true })}
          icon="language"
          hasRipple
        />

        <MenuSurface
          open={this.state.open}
          anchorCorner={Corner.BOTTOM_LEFT}
          onClose={() => this.setState({ open: false })}
          anchorElement={this.state.anchorElement}
        >
          <div className="mdc-list">
            {links}
          </div>
        </MenuSurface>
      </div>
    )
  }
}
