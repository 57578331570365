// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-about-index-js": () => import("./../../../src/pages/all/about/index.js" /* webpackChunkName: "component---src-pages-all-about-index-js" */),
  "component---src-pages-all-about-open-source-js": () => import("./../../../src/pages/all/about/open-source.js" /* webpackChunkName: "component---src-pages-all-about-open-source-js" */),
  "component---src-pages-all-about-privacy-statement-js": () => import("./../../../src/pages/all/about/privacy-statement.js" /* webpackChunkName: "component---src-pages-all-about-privacy-statement-js" */),
  "component---src-pages-all-about-sponsorship-js": () => import("./../../../src/pages/all/about/sponsorship.js" /* webpackChunkName: "component---src-pages-all-about-sponsorship-js" */),
  "component---src-pages-all-contact-js": () => import("./../../../src/pages/all/contact.js" /* webpackChunkName: "component---src-pages-all-contact-js" */),
  "component---src-pages-all-index-js": () => import("./../../../src/pages/all/index.js" /* webpackChunkName: "component---src-pages-all-index-js" */),
  "component---src-pages-all-portfolio-get-1-bike-js": () => import("./../../../src/pages/all/portfolio/get1bike.js" /* webpackChunkName: "component---src-pages-all-portfolio-get-1-bike-js" */),
  "component---src-pages-all-services-backend-web-development-js": () => import("./../../../src/pages/all/services/backend-web-development.js" /* webpackChunkName: "component---src-pages-all-services-backend-web-development-js" */),
  "component---src-pages-all-services-frontend-web-development-js": () => import("./../../../src/pages/all/services/frontend-web-development.js" /* webpackChunkName: "component---src-pages-all-services-frontend-web-development-js" */),
  "component---src-pages-all-services-index-js": () => import("./../../../src/pages/all/services/index.js" /* webpackChunkName: "component---src-pages-all-services-index-js" */),
  "component---src-pages-all-services-websites-js": () => import("./../../../src/pages/all/services/websites.js" /* webpackChunkName: "component---src-pages-all-services-websites-js" */),
  "component---src-pages-all-services-writing-js": () => import("./../../../src/pages/all/services/writing.js" /* webpackChunkName: "component---src-pages-all-services-writing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

