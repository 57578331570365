import React from 'react'
import { Link } from 'gatsby'
import Headroom from 'react-headroom'
import { injectIntl } from 'react-intl'

import MaterialIcon from '@material/react-material-icon'
import { FormattedMessage } from 'react-intl'
import LinkWithLanguage, { urlFor } from './LinkI18n'
import withRipple from '@material/react-ripple'
import Navigation from './Navigation'
import SelectLanguage from './SelectLanguage'

import logo from '../images/informatikamihelac.svg'

//const SelectLanguage = props => (
//<MaterialIcon icon="language" hasRipple {...props} />
//)

const RippleBase = props => {
  const {
    children,
    className = '',
    // You must call `initRipple` from the root element's ref. This attaches the ripple
    // to the element.
    initRipple,
    // include `unbounded` to remove warnings when passing `otherProps` to the
    // root element.
    unbounded,
    ...otherProps
  } = props

  // any classes needed on your component needs to be merged with
  // `className` passed from `props`.
  const classes = `${className}`

  return (
    <div className={classes} ref={initRipple} {...otherProps}>
      {children}
    </div>
  )
}

const Ripple = withRipple(RippleBase)

const ImHeader = ({
  intl,
  navigation,
  siteTitle,
  languages,
  language,
  selected,
  className,
}) => {
  return (
    <Headroom style={{ zIndex: 2 }} className={className}>
      <div className="im-header">
        <div className="im-header__logo-container">
          <LinkWithLanguage className="im-header__logo" to="">
            <Ripple className="im-logo-surface">
              <img src={logo} />
            </Ripple>
          </LinkWithLanguage>
        </div>
        <div className="im-header__actions">
          <SelectLanguage />
        </div>
        <div className="im-header__navigation">
          {navigation && (
            <Navigation
              navigationItems={
                navigation &&
                navigation.map(item => ({
                  slug: item,
                  url: urlFor(language, item),
                  label: intl.formatMessage({ id: `navigation.${item}` }),
                }))
              }
              activeIndex={navigation.indexOf(selected)}
            />
          )}
        </div>
      </div>
    </Headroom>
  )
}

export default injectIntl(ImHeader)
