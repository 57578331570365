import fromPairs from 'lodash/fromPairs'
import { addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import sl from 'react-intl/locale-data/sl'
import hr from 'react-intl/locale-data/hr'

export const addLocaleDataFor = languages =>
  addLocaleData([...sl, ...en, ...hr])
  //NOTE: dynamic require loads all
  //languages.forEach(language => addLocaleData(...require(`react-intl/locale-data/${language}`)))


/**
 * Converts nodes with key, value to object.
 */
export const messagesFromNodes = nodes => {
  const messages = fromPairs(nodes.map(e => [e.node.key, e.node.value]))
  return messages
}
