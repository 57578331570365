import 'babel-polyfill'
import { createWrapPageElement } from 'gatsby-plugin-i18n-pages/wrapPageElementBrowser'
import { IntlProvider } from 'react-intl'

import { languages } from './src/data/languages'
import { addLocaleDataFor } from './src/utils'

addLocaleDataFor(languages)

const importTranslations = language =>
  import(`./src/locale/common/${language}.json`)

export const wrapPageElement = createWrapPageElement(
  IntlProvider,
  languages,
  importTranslations
)
