module.exports = {
  languages: [
    'en',
    'sl',
    'hr',
  ],
  languagesNames: {
    hr: 'Hrvatski',
    sl: 'Slovenščina',
    en: 'English',
  },
  defaultLangKey: 'en',
}
