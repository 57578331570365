import React from 'react'
import { Link } from 'gatsby'
import {injectIntl } from 'react-intl'
import languages from '../data/languages'

export const urlFor = (language, to) => {
  if (language === languages.defaultLangKey && to === '/') {
    return to
  }
  return `/${language}/${to}`
}
const LinkWithLanguage = ({ intl, to, ...props }) => (
  <Link to={urlFor(intl.locale, to)} {...props} />
)

export default injectIntl(LinkWithLanguage)

