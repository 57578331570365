import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'react-intl'

import { ContextProviderComponent, withContext } from './Context'
import Header from '../components/ImHeader'
import Footer from '../components/Footer'
// TODO: use when fixed
// https://github.com/KyleAMathews/typefaces/pull/45
//import 'typeface-francois-one'
//import 'typeface-open-sans'

import '../css/main.css'


const navigation = ['services', 'about', 'contact']

class Layout extends React.Component {

  render() {
    const {
      intl,
      children,
      location,
      layoutContext,
    } = this.props
    const language = intl.locale
    const selected = location.pathname.split('/')[2]
    const hasNavigation = layoutContext.data.navigation === true
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                languages
              }
            }
          }
        `}
        render={data => (
            <>
              <Helmet
                titleTemplate="%s · Informatika Mihelac"
                defaultTitle={data.site.siteMetadata.title}
              >
                <html lang={language} />
                <link
                  href="https://fonts.googleapis.com/icon?family=Material+Icons"
                  rel="stylesheet"
                />
              </Helmet>
              <div className="wrapper">
                <Header
                  className="wrapper__header"
                  siteTitle={data.site.siteMetadata.title}
                  languages={data.site.siteMetadata.languages}
                  language={language}
                  selected={selected}
                  navigation={hasNavigation && navigation}
                />
                <div className="wrapper__main">{children}</div>
                <Footer className="wrapper__footer" />
              </div>
            </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  intl: PropTypes.object.isRequired,
}

const LayoutWithContext = withContext(Layout)

const LayoutWithContextProvider = props => (
  <ContextProviderComponent>
    <LayoutWithContext {...props} />
  </ContextProviderComponent>
)

export default injectIntl(LayoutWithContextProvider)
