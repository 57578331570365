import React from 'react'
import { FormattedMessage } from 'react-intl'
import LinkWithLanguage from './LinkI18n'

import informatikaMihelacUrl from '../images/informatikaMihelac-bw.svg'

const Footer = ({className}) => (
  <footer className={className}>
    <div className="im-footer">
      <img className="im-footer__logo" src={informatikaMihelacUrl} alt="informatikaMihelac" />

      <div className="im-footer__links">
        <LinkWithLanguage to="about/privacy-statement">
          <FormattedMessage id="common.privacy_statement" />
        </LinkWithLanguage>
      </div>
    </div>
  </footer>
)

export default Footer
