import React from 'react'
import { Link } from 'gatsby'


// wraps element in a link that would work only when javascript is disabled
const ALink = (props) => (
  <Link onClick={e => e.preventDefault()} className="alink" {...props} />
)

export default ALink
