import React from 'react'
import Tab from '@material/react-tab'
import { navigate } from 'gatsby'
import ALink from './ALink'

class Navigation extends React.Component {
  tabBoundingRects = {}

  constructor(props) {
    super(props)
    this.state = {
      activeIndex: this.props.activeIndex,
      previousActiveIndex: this.props.activeIndex,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.activeIndex === state.activeIndex) {
      return null
    }
    return {
      activeIndex: props.activeIndex,
    }
  }

  render() {
    const { navigationItems } = this.props
    const { activeIndex, previousActiveIndex } = this.state
    return (
      <div className="mdc-tab-bar">
        <div className="mdc-tab-scroller__scroll-area">
          {navigationItems.map((item, index) => (
            <ALink key={index} to={item.url}>
              <Tab
                className="mdc-tab--im"
                key={item.slug}
                active={index === activeIndex}
                previousIndicatorClientRect={
                  this.tabBoundingRects[previousActiveIndex]
                }
                ref={tabEl => {
                  if (tabEl) {
                    this.tabBoundingRects[
                      index
                    ] = tabEl.computeIndicatorClientRect()
                  }
                }}
                onClick={() => {
                  this.setState({
                    previousActiveIndex: activeIndex,
                    activeIndex: index,
                  })
                  navigate(item.url)
                }}
              >
                <span className="mdc-tab__text-label">{item.label}</span>
              </Tab>
            </ALink>
          ))}
        </div>
      </div>
    )
  }
}

export default Navigation
